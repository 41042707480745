/* Basic colours */

$blue_logo: #0078BF;
$dark_blue_logo: #074A73;
$blue_background: #E6EFF5;
$light_blue_logo: #ADDDF2;
$blue_alert: #D6EEF9;

/* additional colors */

$white: #FFFFFF;
$grey: #898989;
$light_gray: #EBECEC;
$dark_grey: #5C5C5C;
$grey_disabled: #C1C1C1;
$grey_not_selected: #DDDDDD;
$grey_border: #6C757D;

$magenta: #E5097F;
$light_magenta: #EB83B3;
$magenta_alert: #F5C1D9;

$green: #CCD73D;
$dark_green: #00967B;

$dark_red: #b42318;

$orange: #FDA83A;
$orange_alert: #FFE9CD;
$orange_warning: #ff6600;

$purple: #5A3E80;

$default_fontSize: 16px;

$breakpoints: (
    'small': (min-width: 992px),
    'medium': (min-width: 1024px),
    'large': (min-width: 1540px)
);