@import '../../styles/utils/variables';

/* Finished page */

.main-panel {
    background-color: $white;
    padding: 1.875rem;
    border-radius: 0.25rem;
    
    .box {
        &.message-box {
            padding: 0.625rem;
            text-align: center;
            margin-left: -0.938rem;
            margin-right: -0.938rem;
            margin-bottom: 1.5rem; 
        }
        &.error {
            background-color: $light_magenta;
            color: $dark_blue_logo;
        }
    }
}

