@import '../../styles/utils/variables';

/* Login */

.login-container {
    height: 40.625rem;
    width: 100%;
    background: linear-gradient(to bottom, $blue_logo 0%, $blue_logo 50%, $blue_background 50%, $blue_background 100%);
}

.login-container {
    .row {
        &:first-child {
            margin-top:1.25rem;
        }
    }
}

.login-box {
    margin: 2.5rem auto;
    padding: 1.875rem;
    background-color: $white;
    border-radius: 0.25rem;
    max-width: 38.125rem;
}

.login-box {
    h3 {
        text-align: center;
    }

    .text-description {
        color: $grey;
        text-align: center;
        font-size: 0.875rem;
    }

    .btn-primary {
        min-width: 8.75rem;
    }

    .infobox {
        font-size: 0.875rem;
    }

    .profile-button {
        position: relative;
        top: 0.5rem;
    }
}