@import '../../styles/utils/variables';

/* Language selector */
#language-selector-box {
    background: $white url(../../images/arrow_down.svg) 3.4375rem 1rem no-repeat;
    width: 5.0625rem;
    height: 2.875rem;
    -ms-border-radius: 0.25rem;
    border-radius: 0.25rem;
    overflow: hidden;
    position: relative;
    z-index: 1000;

    &:hover {
        background-color: $white;
        overflow: visible;
        border-radius: 0.25rem;
    }

    img {
        margin: 0.375rem 1.5625rem 0.0625rem 0;
        vertical-align: baseline;
    }

    #react-select-2-listbox {
        margin-top: 0px;
    }

    .single-value {
        margin: 0px;
    }

    input {
        caret-color: transparent;
        cursor: pointer;
    }

}