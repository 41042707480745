@import '../../styles/utils/variables';

/* kvůli přepínání tabulátorem v hlavním okně s testy, tab rozhodí display. Nyní se #user-popup-box form defaultně nastavuje na display:none a při #user-popup-box:hover se nastaví na display:block  */
/* User popUp */
#user-popup-box {
    overflow: hidden;
    position: relative;
    z-index: 1000;
    margin-right: 1rem;
    margin-left: 0.5rem;

    form {
        display: none;
    }

    &:hover {
        overflow: visible;

        form {
            display: block;
        }
    }

    .initials {
        background: $white;
        width: 2.625rem;
        height: 2.625rem;
        border-radius: 50%;
        text-align: center;
        color: $blue_logo;
        line-height: 2.625rem;
        cursor: pointer;
    }

    .user-popup {
        position: absolute;
        background-color: $white;
        border-radius: 0.25rem;
        text-align: center;
        width: 15rem;
        margin-left: -10.625rem;
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;

        .full-name {
            color: $blue_logo;
            font-size: 1.375rem;
        }

        .delimiter {
            border-bottom: 0.0625rem solid $light_gray;
            padding-bottom: 0.625rem;
            margin-bottom: 0.625rem;
        }

        .item {
            font-size: 1rem;
            color: $dark_blue_logo;
            line-height: 2rem;
        }
    }

    .btn-popup {
        border-radius: 0.25rem;
        border: 0.0625rem solid grey;
        color: $blue_logo;
        font-size: 1rem;
        text-align: center;
        min-width: 8.75rem;
        font-weight: bold;
    }
}