@import '../../styles/utils/variables';

/* Navbar */

a {
    &.navbar-brand {
        white-space: normal;
        text-align: center;
        word-break: break-all;
    }
}

.navbar {
    background-color: $blue_logo;
    color: $white;
    height: 4.6875rem;
}

.navbar-nav {
    &.navbar-center {
        position: absolute;
        left: 50%;
        transform: translatex(-50%);

        h1 {
            font-size: 1.75rem;
            line-height: 2.375rem;
            text-align: center;
            color: $white;
            margin: 0;
            font-weight: 300;
        }
    }

    .dropdown {
        margin-right: 0.625rem;
    }
}

.nav-brand-logo {
    margin-right: 0.625rem;
}