html {
    font-size: $default_fontSize;
    position: relative;
    font-family: 'Open Sans';
    font-style: normal;
    min-height: 100%;
}

body {
    background: $blue_background;
}

h1 {
    font-weight: 400;
    font-size: 1.75rem;
    line-height: 2.375rem;
    color: $blue_logo;
}

h2 {
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.875rem;
    color: $blue_logo;
}

h3 {
    font-weight: 400;
    font-size: 1.75rem;
    line-height: 1.5625rem;
    color: $blue_logo;
}

.text-left {
    text-align: left !important;
}
  
.text-right {
    text-align: right !important;
}
  
.text-center {
    text-align: center !important;
}

hr {
    &.full {
        margin-left: -1.875rem;
        margin-right: -1.875rem;
    }
}

.btn-block {
    display: block;
    width: 100%;
}

/* boxes */

.box {
    box-sizing: border-box;
    border-radius: 0.25rem;
    padding: 0.625rem 0.9375rem;
    color: $dark_blue_logo;
    border: 1px solid;
    
    .info {
        border-color: $dark_blue_logo;
        background: $light_blue_logo;
    }

    .warning {
        border-color: $orange;
        background: $orange_alert;
    }

    .error {
        border-color: $magenta;
        background: $magenta_alert;
    }
}

/* Buttons */

.btn-link {
    color: $blue_logo;
}

/* Error under input */

.error-message {
    color: $magenta;
}

/* Toast message */

.toast {
    margin-left: 0.625rem;
    width: 12.5rem;
    position: fixed;
    bottom: 0.625rem;

    .toast-header {
        background-color: $dark_green;
        color: $white;

        &.error {
            background-color: $dark_red;
        }
    }
}

