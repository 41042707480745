@import '../../styles/utils/variables';

/* Dashboard */

.main-panel {
    background-color: $white;
    padding: 1.875rem 1.875rem 1.875rem 1.875rem;
    border-radius: 0.25rem; 
}
  
h3 {
    &.mb-3 {
        font-size: 1.75rem;
        line-height: 2.375rem;
        color: $blue_logo;
        font-weight: normal; 
    }
}

a.small, .btn-link {
    color: $blue_logo;
    text-decoration: none;
}

.box {
    box-sizing: border-box;
    border-radius: 0.25rem;
    padding: 0.625rem 0.938rem; 

    &.active {
        background-color: $light_blue_logo;
        color: $dark_blue_logo;
        margin-bottom: 1.25rem; 
    }

    &.inactive {
        background: $light_gray;
        color: $grey;
        margin-bottom: 1.25rem; 
    }

    &.error {
        border: 0.063rem solid $magenta; 
    }

    &.warning {
        border: 0.063rem solid $orange_warning; 
    }

    .row {
        &.test-heading {
            margin-bottom: -1.25rem;
        }

        &.testpart {
            font-size: 0.75rem;
            line-height: 1.5rem;
            align-items: center;
            justify-content: center;
            margin-left: 0.313rem;

            .status {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 0.75rem;
            }

            .missed {
                color: $magenta;
            }

            .exam-name {
                font-size: 1rem;
            }
        }
    }
}
    
.infobox {
    background: $light_blue_logo;
    border: 1px solid $dark_blue_logo;
    color: $dark_blue_logo; 
}

.message-box {
    padding: 10px;
    text-align: center;
    margin-left: -15px;
    margin-right: -15px;
    p:last-of-type {
        margin-bottom: 0;
    }
    &.Error {
        background-color: #EB83B3;
        color: $dark_blue_logo;
    }
}
    
.btn {
    margin: 0.313rem 0 0.313rem 0;

    &.btn-primary {
        color: $white;
        background-color: $blue_logo;
        border-color: $blue_logo;
        font-weight: bold; 
    }

    &.btn-light {
        border-color: $grey;
        color: $blue_logo;
        font-weight: bold; 
    }

    &.btn-col {
        max-width: 9.063rem; 
        width: 100%;
    }

    &.btn-primary {
        &:disabled {
            cursor: not-allowed; 
            background-color: $grey_disabled;
            border-color: $grey;
        }
    }

    &.btn-outline-primary {
        color: $blue_logo;
        background-color: $white;
        border-color: $grey;
    }
}

.pagination {
    justify-content: center;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;

    .page {
        color: $blue_logo;
        padding: 0.438rem 0;
        width: 2.5rem;
        height: 2.5rem;
        text-decoration: none;
        font-weight: bold;
        margin: 0 0.188rem;
        border-radius: 0.313rem;
        border: 0.063rem solid transparent;
        background-color: white;
        border-color: $grey_border;

        &.active {
            background-color: $blue_logo;
            color: $white;
            border-radius: 0.313rem;
            border: 0.063rem solid transparent;
        }

        &:hover {
            &:not(.active) {
                background-color: $grey_not_selected;
                border-radius: 5px;
            }
        }
    }

    .break {
        padding: 0.438rem 0;
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        font-weight: bold;
        a {
          text-decoration: none;
          color: $blue_logo;
        }
    }

    .btn-arrow {
        a {
            color: $blue_logo;
            font-weight: bold;
            font-size: 32px;
            line-height: 13px;
            padding: 0;
            text-decoration: none;
        }
    }
}
